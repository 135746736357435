import Store from 'vuex';

let store;

export function Vuex(){
  return Store;
};

export function AppStore(){
  if(!store){
    store = new Store.Store({
      state: {
        localeSelectorOpen: false
      },
      mutations: {
        localeSelectorToggle(state){
          state.localeSelectorOpen = !state.localeSelectorOpen;
        },
        localeSelectorClose(state){
          state.localeSelectorOpen = false;
        }
      }
    });
  }

  return store;
};