<template>
    <footer class="app-footer">
      <ul v-if="getLegalDetailsList">
        <li v-for="(legalDetail, index) in getLegalDetailsList" :key="index">
          <strong>{{legalDetail.detail}}</strong>
          <p>{{legalDetail.text}}</p>
        </li>
      </ul>
      <div>
        <a href="https://www.paf.es/safer-gaming/age-restrictions"><img src="../../.resources/pngs/18.png"></a>
        <a href="https://www.ordenacionjuego.es/es/op-PafConsulting"><img src="../../.resources/pngs/juego_1.png"></a>
        <a href="https://www.juegoseguro.es/"><img src="../../.resources/pngs/juego_2.png"></a>
        <a href="https://www.paf.es/safer-gaming"><img src="../../.resources/pngs/juego_3.png"></a>
        <a href="https://www.ordenacionjuego.es/es/rgiaj"><img src="../../.resources/pngs/auto_1.png"></a>
        <a href="https://www.paf.es/profile/self-exclusion#self-exclusion"><img src="../../.resources/pngs/auto_2.png"></a>
        <a href="https://www.jugarbien.es/"><img src="../../.resources/pngs/jugar.png"></a>
      </div>
    </footer>
</template>

<script>
export default {
  computed: {
    getLegalDetailsList() {
      const legalDetails = this.$t("legal-details.items");
      return Array.isArray(legalDetails) ? legalDetails : undefined;
    },
  },
};
</script>

<style scoped>
  @import url('./footer.css');
</style>