import Router from 'vue-router';
import AppRoutes from 'src/.generated/routes';

let router;

export function VueRouter(){
  return Router;
};

export function AppRouter(){
  if(!router){
    router = new Router({
      mode: 'history',
      routes: AppRoutes
    });
  }

  return router;
};