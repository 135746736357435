import Vue from 'vue';
import VueMeta from 'vue-meta';

import { AppStore, Vuex } from 'src/store';
import { AppRouter, VueRouter } from 'src/router';
import { AppLocalization, Vuei18n } from 'src/localization';
import AppStyles from 'src/.generated/styles';

Vue.use(VueMeta);
Vue.use(Vuex());
Vue.use(VueRouter());
Vue.use(Vuei18n());

import App from 'src/app.vue';

AppRouter().beforeEach((to, from, next) => {
  AppStore().commit('localeSelectorClose');

  if(to.meta && to.meta.localeLoader){
    to.meta.localeLoader().then(response => AppLocalization().setLocaleMessage('en', response));
  }

  next();
});

Vue.prototype.$translation = function(key, fallback){
  if(fallback === undefined) fallback = key;
  return (this.$t(key) !== key) ? this.$t(key) : fallback;
}

export default new Vue({
  store: AppStore(),
  router: AppRouter(),
  i18n: AppLocalization(),
  render: h => h(App)
}).$mount("#app");