<template>
  <div class="app-locale-selector" v-if="locales.length > 1">
    <button
      :aria-controls="localeSelectorId"
      :aria-expanded="localeSelectorOpen"
      @click="localeToggle"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M256,81c-96.6,0-175,78.4-175,175s78.4,175,175,175s175-78.4,175-175S352.6,81,256,81z M279.2,400
          c-7.6,1.2-15.4,1.8-23.2,1.8c-7.9,0-15.6-0.6-23.2-1.8c-5-6.9-9.7-15.6-13.8-25.8c-3-7.3-5.6-15.1-7.9-23.4h90
          c-2.3,8.3-5,16.2-7.9,23.4C288.9,384.4,284.2,393.1,279.2,400z M207.4,336.2c-4.9-22.1-7.7-47-8.2-72.9h113.6
          c-0.4,25.9-3.3,50.8-8.2,72.9H207.4z M110.3,263.3h74.3c0.4,26.2,3.2,50.9,7.9,72.9h-58.3C119.9,314.6,111.6,289.6,110.3,263.3z
          M232.8,112c7.6-1.2,15.4-1.8,23.2-1.8c7.9,0,15.6,0.6,23.2,1.8c5,6.9,9.7,15.6,13.8,25.8c3,7.3,5.6,15.1,8,23.4h-90
          c2.3-8.3,5-16.2,7.9-23.4C223.1,127.6,227.8,118.9,232.8,112z M304.7,175.8c4.9,22.2,7.7,47,8.2,72.9H199.2
          c0.4-26,3.3-50.8,8.2-72.9H304.7z M184.6,248.7h-74.3c1.3-26.3,9.5-51.3,23.8-72.9h58.3C187.8,197.8,185,222.5,184.6,248.7z
          M327.4,263.3h74.3c-1.3,26.3-9.5,51.3-23.8,72.9h-58.3C324.2,314.2,327,289.5,327.4,263.3z M327.4,248.7
          c-0.4-26.2-3.2-50.9-7.9-72.9h58.3c14.3,21.6,22.5,46.6,23.8,72.9H327.4z M366.8,161.2h-50.7c-4.5-17.1-10.2-32.2-16.9-44.6
          c22.4,6.9,42.9,19.2,59.9,36.2C361.8,155.6,364.4,158.4,366.8,161.2z M212.8,116.7c-6.6,12.3-12.3,27.4-16.9,44.6h-50.7
          c2.4-2.9,5-5.6,7.7-8.3C169.9,135.9,190.4,123.6,212.8,116.7z M145.2,350.8h50.7c4.5,17.1,10.2,32.2,16.9,44.6
          c-22.4-6.9-42.9-19.2-59.9-36.2C150.2,356.4,147.6,353.6,145.2,350.8z M299.2,395.3c6.6-12.3,12.3-27.4,16.9-44.6h50.7
          c-2.4,2.9-5,5.6-7.7,8.3C342.1,376.1,321.6,388.4,299.2,395.3z" fill="currentColor"></path>
      </svg>
      <span>{{ localeTranslation(localeCurrent) }}</span>
    </button>
    <nav
      :id="localeSelectorId"
      :aria-hidden="!localeSelectorOpen"
    >
      <router-link
        v-for="(route, index) in locales"
        :key="index"
        :to="route.path"
      >{{ localeTranslation(route.name) }}</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  data(){
    return {
      localeTranslations: {
        en: 'English',
        fi: 'Suomi',
        sv: 'Svenska',
        et: 'Eesti',
        lv: 'Latviešu',
        ru: 'Pусский',
        de: 'Deutsch',
        fr: 'Français',
        it: 'Italiano',
        es: 'Español',
      }
    }
  },
  computed: {
    localeSelectorId(){
      return `app-locale-selector-${this._uid}`;
    },
    localeSelectorOpen(){
      return this.$store.state.localeSelectorOpen;
    },
    localeCurrent(){
      return this.$route.name;
    },
    locales(){
      // Maintenance locales
      if(this.$route.name.startsWith('maintenance-'))
        return this.$router.options.routes.filter(route => route.name && route.name.startsWith('maintenance-') && !route.redirect);

      // Restricted locales
      if(this.$route.name.startsWith('restricted-'))
        return this.$router.options.routes.filter(route => route.name && route.name.startsWith('restricted-') && !route.redirect);
    }
  },
  methods: {
    localeTranslation(routeName){
      if(!routeName) return;

      const locale = routeName.split('-').pop();

      if(locale in this.localeTranslations) return this.localeTranslations[locale];

      return locale;
    },
    localeToggle(){
      this.$store.commit('localeSelectorToggle');
    }
  }
}
</script>

<style>
.app-locale-selector {
  position: relative;
}

.app-locale-selector button {
  width: 5em;
  height: 100%;
  background: transparent;
  padding: 0 1em;
  border: none;

  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.app-locale-selector button svg {
  width: 1.75em;
  height: 1.75em;
}

.app-locale-selector button span {
  font-size: .75em;
}

.app-locale-selector nav {
  min-width: 12em;
  background: var(--app-header-background);
  box-shadow: var(--app-shadow-mid), var(--app-shadow-high);
  border-top: .3em solid var(--app-color-brand);
  border-radius: .2em;

  position: absolute;
  top: calc(100% + .75em);
  right: .45em;

  transform-origin: calc(100% - 1.5em) -.75em;
  opacity: 1;

  will-change: transform;
  transition:
    transform .25s cubic-bezier(0,1.04,.56,1.05),
    opacity .25s cubic-bezier(0,1.04,.56,1.05);
}

.app-locale-selector nav::before {
  content: '';
  width: 0;
  height: 0;
  border: .5em solid transparent;
  border-bottom-color: var(--app-color-brand);

  position: absolute;
  bottom: calc(100% + .25em);
  right: 1.5em;
}

.app-locale-selector nav[aria-hidden] {
  transform: scale(0);
  opacity: 0;
}

.app-locale-selector a {
  padding: .5em 1em;
  display: block;

  text-decoration: none;
  color: inherit;
}

.app-locale-selector a:first-child {
  padding-top: 1em;
}

.app-locale-selector a:last-child {
  padding-bottom: 1em;
}

.app-locale-selector a.router-link-exact-active {
  font-weight: bold;
}
</style>