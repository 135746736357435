import Localization from 'vue-i18n';

let localization;

export function Vuei18n(){
  return Localization;
};

export function AppLocalization(){
  if(!localization){
    localization = new Localization({
      locale: 'en'
    });
  }

  return localization;
};