<template>
  <header class="app-header">
    <a :href="$translation('site.url', null)" class="app-logo"></a>
    <app-locale-selector v-if="!hiddenLocaleSelector"></app-locale-selector>
  </header>
</template>

<script>
import AppLocaleSelector from 'src/.maintenance/components/app-locale-selector.vue';

export default {
  components: {
    AppLocaleSelector
  },
  computed: {
    hiddenLocaleSelector(){
      return !this.$route.name;
    }
  }
}
</script>

<style scoped>
.app-header {
  height: var(--app-header-height);
  background: var(--app-header-background);
  box-shadow: var(--app-shadow-low), var(--app-shadow-mid);

  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: stretch;
}

.app-logo {
  padding: 0 1em;
  margin: 0 auto 0 0;

  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
}

.app-logo::after {
  display: block;
}
</style>
